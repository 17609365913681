
.hero {
  width: 100%;
  height: auto;
  /* color: white; */
  position: relative;
}

.hero::before {
  content: "";
  position: absolute;
  top: -200px;
  bottom: 0;
  left: 0;
  right: 0;
  /* background-image: url("/public//Images/hero_background.png");
  background-repeat: no-repeat;
  background-size: cover; */
  z-index: -1;
}

.progressbar {
  position: relative;
  overflow: hidden;
  height: 6px;
  border-radius: 1px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50px;
  /* z-index: 1000001; */
}

.sold {
}

.sales {
  background: rgba(0, 0, 0, 1);
}

.stats {
  background: rgba(0, 0, 0, 0.6);
  position: relative;
}

@media (max-width: 1024px) {
  .stats::after {
    position: absolute;
    content: "";
    top: 15%;
    left: 62%;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
  }
}

.competitions {
  width: 100%;
  /* background: rgb(17, 16, 14);
  background: linear-gradient(
    90deg,
    rgba(17, 16, 14, 1) 52%,
    rgba(89, 41, 7, 1) 83%,
    rgba(215, 121, 2, 1) 100%
  ); */
  position: relative;
}

@media (max-width: 1024px) {
  .competitions {
    /* background: rgb(42, 32, 9);
    background: linear-gradient(
      90deg,
      rgba(42, 32, 9, 1) 0%,
      rgba(170, 89, 4, 1) 100%
    ); */
  }
}

.ticketPriceBox {
  background: rgba(0, 0, 0, 0.4);
}

.competitionBox {
  background: rgba(0, 0, 0, 0.5);
}

@media (min-width: 2900px) {
  .competitionBox {
    width: 50%;
  }
}

.winnerBox {
  background: rgb(115, 27, 80);
  /* background: linear-gradient(
    90deg,
    rgba(115, 27, 80, 1) 0%,
    rgba(43, 43, 43, 1) 7%
  );  */
}

.winnerDetails {
  background: rgba(0, 0, 0, 0.8);
  display: none;
}

.winner:hover .winnerDetails {
  display: block;
}

@media (max-width: 1280px) {
  .winnerDetails {
    display: block;
  }
}

.advertBox {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  object-fit: cover;
}

@media screen and (max-width: 1024px) {
  .advertBox {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    object-fit: cover;
  }
}

.phoneStores {
  background: rgb(199, 36, 86);
  background: linear-gradient(
    90deg,
    rgba(199, 36, 86, 1) 7%,
    rgba(43, 43, 43, 1) 78%
  );
}

.liveCompetition {
  position: relative;
}

/* .liveCompetition::after {
  position: absolute;
  content: "";
  bottom: 0;
  top: 60%;
  left: 0;
  right: 0;
  background: rgb(17, 16, 14);
  background: linear-gradient(
    90deg,
    rgba(17, 16, 14, 1) 52%,
    rgba(89, 41, 7, 1) 83%,
    rgba(215, 121, 2, 1) 100%
  );
  z-index: -1;
  filter: blur(10px);
} */

/* .lastChance {
  background: rgb(17, 16, 14);
  background: linear-gradient(
    90deg,
    rgba(17, 16, 14, 1) 52%,
    rgba(89, 41, 7, 1) 83%,
    rgba(215, 121, 2, 1) 100%
  );
} */

/* .drawnCompetition {
  position: relative;
  background: rgb(205, 36, 88);
  background: linear-gradient(
    90deg,
    rgba(205, 36, 88, 1) 9%,
    rgba(43, 43, 43, 1) 63%
  );
  z-index: 2;
} */

/* .drawnCompetition::after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  left: 20%;
  bottom: 80%;
  background: rgb(43, 43, 43);
  background: linear-gradient(
    90deg,
    rgba(43, 43, 43, 1) 0%,
    rgba(171, 104, 16, 1) 98%
  );
  filter: blur(10px);
  z-index: -1;
} */

/* .competitionDetails {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 35%,
    rgba(152, 73, 32, 1) 63%
  );
} */

/* .howToPlayPage {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 35%,
    rgba(152, 73, 32, 1) 61%
  );
} */

@media (max-width: 1024px) {
  .competitionDetails {
    background: #2b2b2b;
  }

  .howToPlayPage {
    background: rgb(33, 27, 11);
    background: linear-gradient(
      90deg,
      rgba(33, 27, 11, 1) 3%,
      rgba(167, 86, 4, 1) 100%
    );
  }
}

.howToPlay {
  /* background: rgba(0, 0, 0, 0.7); */
  /* background: #322924; */
  position: relative;
}

/* .howToPlay::after {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  left: 26%;
  top: 26%;
  background: url("/public/Images/THEPRIZE_TokenLogo_EH-03.svg");
  background-size: 500px;
  background-repeat: no-repeat;
  z-index: 100;
  opacity: 0.5;
} */
@media (max-width: 640px) {
  .howToPlay::after {
    background: none;
  }
}

/* .howToPlay::before {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  left: 26%;
  top: 36%;
  background: url("/public/Images/THEPRIZE_TokenLogo_EH-03.svg");
  background-size: 500px;
  background-repeat: no-repeat;
  z-index: 100;
  opacity: 0.5;
} */

@media (max-width: 640px) {
  .howToPlay::before {
    background: none;
  }
}

@media (max-width: 1024px) {
  .howToPlay::after {
    left: 40%;
    top: 10%;
  }

  .howToPlay::before {
    left: 38%;
    top: 23%;
  }
}

.howToPlayList {
  list-style: decimal;
}

.faq {
  background: rgb(156, 19, 100);
  background: linear-gradient(
    90deg,
    rgba(156, 19, 100, 1) 0%,
    rgba(43, 43, 43, 1) 49%
  );
}

@media (max-width: 1024px) {
  .faq {
    background: rgb(130, 40, 66);
    background: linear-gradient(
      90deg,
      rgba(130, 40, 66, 1) 59%,
      rgba(86, 44, 53, 1) 100%
    );
  }
}

.howToPlayFaq {
  position: relative;
  z-index: 101;
}

.howToPlayFaq::before {
  position: absolute;
  content: "";
  top: -70%;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
  background: rgb(51, 47, 41);
  background: linear-gradient(
    90deg,
    rgba(51, 47, 41, 1) 10%,
    rgba(210, 123, 7, 1) 61%
  );
  filter: blur(5px);
}

.phoneStoresfaq {
  background: rgb(52, 47, 42);
  background: linear-gradient(
    90deg,
    rgba(52, 47, 42, 1) 16%,
    rgba(213, 124, 7, 1) 81%
  );
}

.smallImgs {
  position: relative;
}

.smallImgs::before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 100;
  border-radius: 1rem;
}

.inactive {
  background: rgba(255, 255, 255, 0.1);
}

.sliderControl {
  background: rgb(102, 65, 16);
  background: linear-gradient(
    90deg,
    rgba(102, 65, 16, 1) 52%,
    rgba(160, 91, 11, 1) 100%
  );
}

.filter {
  background: rgba(255, 255, 255, 0.2);
}

.ticketsButton {
  box-shadow: 3.55832px 3.55832px 9.48887px #000000;
}

.swiper {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.swiper-slide {
  text-align: center;
  /* font-size: 18px; */
  background: white;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.mySwiper .swiper-pagination .swiper-pagination-bullet {
  margin-top: 5em !important;
}

.swiper-slide {
  /* width: auto !important; */
}

/* @media (min-width: 641px) {
  .swiper-slide {
    width: 800px !important;
  }
} */

.range-container {
  background-color: rgba(255, 255, 255, 0.1);
}

.range-slider {
  appearance: none;
  width: 100%;
  height: 5px;
  background-color: transparent;
}

.range-slider::-webkit-slider-thumb {
  appearance: none;
  width: 2rem;
  height: 3rem;
  cursor: grab;
  background-color: transparent;
  /* background: url("/public/Images/range_chicken.png"); */
  background-repeat: no-repeat;
  background-size: contain;
}

.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
}

.howItWorksBox {
  /* background: rgb(255, 218, 0);
  background: linear-gradient(
    180deg,
    rgba(255, 218, 0, 1) 34%,
    rgba(255, 159, 0, 1) 49%
  );

  background: rgb(255, 189, 0);
  background: linear-gradient(
    180deg,
    rgba(255, 189, 0, 1) 34%,
    rgba(255, 159, 0, 1) 49%
  ); */
  /* box-shadow: 6px 0px 20px 16px rgba(17, 17, 26, 0.18); */

  position: relative;
  box-shadow: 10px 10px 19px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 10px 10px 19px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 19px 0px rgba(0, 0, 0, 0.75);
}

.howItWorksBox::after {
  position: absolute;
  content: "";
  /* top: 5%;
  bottom: 0;
  right: 0; */
  left: 0;
  width: 40em;
  height: 100%;
  /* background: url("/public/Images/wheel_big.png"); */
  background-repeat: no-repeat;
  opacity: 0.2;
}

@media (max-width: 768px) {
  .howItWorksBox::after {
    display: none;
  }
}

.policyPage {
  background: rgba(0, 0, 0, 0.6);
}

.competitionOverlay {
  /* background: white; */
  opacity: 0.4;
}

.timeRemaining {
  position: relative;
}

/* .timeRemaining::after {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  top: -30%;
  left: 40%;
  background: url("/public/Images/wheel.png");
  background-repeat: no-repeat;
  opacity: 0.2;
} */

.timeRemaining2 {
  position: relative;
}

.timeRemaining2::after {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  top: -26%;
  left: 52%;
  background: url("/public/Images/small_wheel.png");
  background-repeat: no-repeat;
  opacity: 0.05;
}
