.embla {
  /* max-width: 48rem; */
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 80%;
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
  gap: 1em;
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  background: white;
}
.embla__slide__number {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 1.8rem;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--slide-height);
}
.embla__controls {
  justify-content: space-between;
  gap: 1.2rem;
  margin: 1em 0em;
}
.embla__dots {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  /* justify-content: flex-end; */
  width: 111px;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  z-index: 10000000001;
}
.embla__dot {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 31px;
  height: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  opacity: 0.15;
}
/* .embla__dot:after {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: "";
} */
.embla__dot--selected {
  /* box-shadow: inset 0 0 0 0.2rem var(--text-body); */
  /* background: black; */
  width: 31px;
  height: 4px;
  opacity: 1;

  @apply bg-primary;
}
