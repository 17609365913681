.prizeCardWonBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 220.43px;
  height: 120.76px;
  background-image: url("/public//Images/WinNowTickets_EH-02-cropped.svg");
  background-size: cover;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.prizeCardBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 220.43px;
  height: 120.76px;
  background-image: url("/public//Images/WinNowTickets_EH-03-cropped.svg");
  background-size: cover;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 1024px) {
  .prizeCardWonBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 104.9px;
    height: 58.13px;
    background-image: url("/public//Images/WinNowTickets_EH-02-cropped.svg");
    background-size: cover;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }

  .prizeCardBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 104.9px;
    height: 58.13px;
    background-image: url("/public//Images/WinNowTickets_EH-03-cropped.svg");
    background-size: cover;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }
}
