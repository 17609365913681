@keyframes stripe-animation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -180px 0;
  }
}

.progress-bar {
  position: relative;
  width: 100%;
  height: 25px;
  background: #333;;
  border-radius: 20px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background: repeating-linear-gradient(
    -80deg,
    #f00090 0px,
    #f00090 12px,  /* Red stripe */
    #ffffff 12px,
    #ffffff 20px   /* Adjusted to make the last red stripe hidden */
  );
  border-radius: 20px 0 0 20px;
  transition: width 0.3s all;
  animation: stripe-animation 5s linear infinite;
}
