/* in case we need to add some custom styles to the dropdown */
.dropdown__options {
    padding-top: 20px;
    padding-bottom: 15.68px;
    transform: translateY(-10px);
    background-color: black;
    position: absolute;
    z-index: 1;
    width: 100%;
    overflow: hidden;
    left:0;
    padding-left: clamp(0vw,13.44px,18px);
    padding-right: clamp(0vw,13.44px,18px);
}