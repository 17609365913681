.sideBar {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  z-index: 1000000001;
}

.liveCompetitionPage {
  position: relative;
}

.liveCompetitionPage::after {
  /* position: absolute;
  content: "";
  bottom: 0;
  top: 80%;
  left: -20%;
  right: 0;
  background: rgb(17, 16, 14);
  background: linear-gradient(
    90deg,
    rgba(17, 16, 14, 1) 52%,
    rgba(89, 41, 7, 1) 83%,
    rgba(215, 121, 2, 1) 100%
  );
  z-index: -1;
  filter: blur(10px); */
}

.dashboardBottom {
  background: rgb(43, 43, 43);
  background: linear-gradient(
    90deg,
    rgba(43, 43, 43, 1) 19%,
    rgba(86, 73, 34, 1) 53%,
    rgba(247, 185, 2, 1) 100%
  );
  position: relative;
}

.dashboardBottom::after {
  position: absolute;
  content: "";
  top: 7.4%;
  bottom: 0;
  left: 33%;
  right: 0;
  background: url("/public/Images/background_chicken.png");
  z-index: 1;
  background-repeat: no-repeat;
}

@media (max-width: 1024px) {
  .dashboardBottom {
    background: none;
  }
}

.popup {
  background-color: rgba(0, 0, 0, 0.7);
  transition: ease-in 150ms;
}

.popup::before {
  position: absolute;
  content: "";
  top: -82vh;
  bottom: -10%;
  left: -20%;
  right: -20%;
  /* background: rgba(255, 255, 255, 0.5); */
  background: rgba(0, 0, 0, 0.3);
  z-index: -1;
}

.popup::after {
  /* position: absolute; */
  content: "";
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  /* background: url("/public/Images/medium_bg_chicken.png"); */
  background-repeat: no-repeat;
}

.userPage {
  position: relative;
}

.userPage::after {
  position: absolute;
  content: "";
  bottom: -2em;
  left: -30%;
  right: 0;
  top: 63%;
  background: rgb(43, 43, 43);
  background: rgb(43, 43, 43);
  background: linear-gradient(
    90deg,
    rgba(43, 43, 43, 1) 19%,
    rgba(86, 73, 34, 1) 82%,
    rgba(167, 129, 18, 1) 100%
  );
  z-index: -1;
  filter: blur(5px);
}

.userPageForm {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 30px;
}

td {
  margin: 1em;
}

.enteredComps {
  position: relative;
}

/* .enteredComps::after {
  position: absolute;
  content: "";
  bottom: -2em;
  left: -30%;
  right: 0;
  top: 63%;
  background: rgb(43, 43, 43);
  background: rgb(43, 43, 43); */
  /* background: linear-gradient(
    90deg,
    rgba(43, 43, 43, 1) 19%,
    rgba(86, 73, 34, 1) 82%,
    rgba(167, 129, 18, 1) 100%
  ); */
  /* z-index: -1;
  filter: blur(5px); */
/* } */

.compDetails {
  background: rgba(0, 0, 0, 0.8);
}

@media (max-width: 1024px) {
  .detailsFaq {
    background: rgb(33, 27, 11);
    background: linear-gradient(
      90deg,
      rgba(33, 27, 11, 1) 3%,
      rgba(167, 86, 4, 1) 100%
    );
  }
}
