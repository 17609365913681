/* Navbar styling */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "MontBlancRegular";
    src: local("MontBlancRegular"),
      url("/public/fonts/WEB/MontBlanc-Regular.otf") format("otf");
  }
  @font-face {
    font-family: "MontBlancThin";
    src: url("/public/fonts/WEB/MontBlanc-Thin.otf") format("otf");
  }

  @font-face {
    font-family: "Sequel100Wide";
    src: local("Sequel100Wide"),
      url("/public/fonts/WEB/Sequel100Wide-95.ttf") format("truetype");
  }
  @font-face {
    font-family: "Sequel100WideVF";
    src: local("Sequel100WideVF"),
      url("/public/fonts/WEB/Sequel100WideVF.ttf") format("ttf");
  }
}

@layer components {
  .pageHeading {
    @apply flex text-[33.93px] tracking-wider font-[Sequel100WideVF] text-white my-[1em] sm:text-[20px];
  }
  .pageHeading2 {
    @apply flex text-[33.93px] tracking-wider font-[Sequel100WideVF] text-white sm:text-[20px];
  }

  /* .custom-scrollbar {
    overflow-y: scroll; 
  } */
}

.navBar {
  /* background: rgba(255, 255, 255, 0.1); */
  /* position: fixed; */

  position: sticky;
  top: 0;
  z-index: 100000001;
  backdrop-filter: blur(8px);

  @apply bg-navbar_bg;
}

@media (max-width: 1280px) {
  .navBar {
    /* background: rgba(255, 255, 255, 0.05); */
    @apply bg-navbar_bg;
  }
}

/* Footer styling */
.footer {
  /* background: linear-gradient(90deg, #ff0010 0%, #ff8c00 100%); */

  color: white;
}

/* .footer::before {
  content: "";
  position: absolute;
  right: 0;
  left: 66%;
  bottom: 0;
  top: -10%;
  background-image: url("/public/Images/THEPRIZE_TokenLogo_EH-03.svg");
  background-size: 35vw;
  background-repeat: no-repeat;
  opacity: 1;
  z-index: -1;
} */

.input {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  outline: none;
}

.auth {
  background: rgba(0, 0, 0, 0.5);
  /* position: sticky; */
  top: 0;
  z-index: 100001;
  backdrop-filter: blur(10px);
}

.authSubmit {
  background: rgb(255, 116, 5);
  background: linear-gradient(
    90deg,
    rgba(255, 116, 5, 1) 62%,
    rgba(255, 68, 9, 1) 100%
  );
}

.top {
  right: -7em;
  height: 4px;
  width: 40px;
  background: white;
  border-radius: 50px;
  position: absolute;
  transition: 0.7s ease;
  top: 0;
  transform: rotate(0);
}
.middle {
  right: -7em;
  height: 4px;
  width: 40px;
  background: white;
  border-radius: 50px;
  position: absolute;
  transition: 0.7s ease;
  top: 0;
  transform: rotate(0);
  transform: translateY(12px);
}
.bottom {
  right: -7em;
  height: 4px;
  width: 40px;
  background: white;
  border-radius: 50px;
  position: absolute;
  transition: 0.7s ease;
  top: 0;
  transform: rotate(0);
  transform: translateY(24px);
}

.hamburger {
  display: none;
}

.open {
  display: none;
}

.open > .top {
  transform: rotate(45deg) translateY(6px) translate(6px);
  /* background: #ffc000; */
  @apply bg-primary;
}

.open > .middle {
  display: none;
  /* background: #ffc000; */
  @apply bg-primary;
}

.open > .bottom {
  transform: rotate(-45deg) translateY(6px) translate(-6px);
  /* background: #ffc000; */
  @apply bg-primary;
}

@media (max-width: 1280px) {
  .hamburger {
    position: relative;
    right: 7em;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 60px;
    width: 40px;
    margin-top: 1.2em;
    transition: 0.7s ease;
  }

  .open {
    position: relative;
    right: 7em;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 60px;
    width: 40px;
    margin-top: 1.2em;
    transform: rotate(90deg);
    transform: translateY(0px);
  }
}

.mobileNav {
  background: #2e2e2e;
}

.pbarBackground {
  background-color: rgba(255, 255, 255, 0.1);
}

/* .mobileNav::after {
  position: absolute;
  content: "";
  right: 0;

  top: 44%;

  left: 68%;
  bottom: 0;
  
  background: url("/public/Images/coin_colored_half.svg");
  background-repeat: no-repeat;
  opacity: 0.1;
} */

.mobileNavbarBg {
  position: relative;
  z-index: 100001;
}

.mobileNavbarBg::before {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  left: 0;
  bottom: -90%;
  background: rgba(0, 0, 0, 0.8);
  z-index: -1;
  filter: blur(15px);
}

@media (max-width: 1280px) {
  .page {
    position: relative;
    z-index: 1;
  }

  .page::before {
    position: absolute;
    content: "";
    right: 0;
    top: -112px;
    left: 0;
    bottom: 96%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 10;
    filter: blur(15px);
  }
}

/* .swiper_container {
  height: auto;
  position: relative !important;
  margin-bottom: 2em !important;
} */

.slider-controler {
  position: relative;
  z-index: 100000000001;
  margin-top: 3em;
  margin-bottom: -1em;
}

.swiper-pagination {
  /* width: 120px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-left: 23%; */
}

.swiper-pagination .swiper-pagination-bullet {
  /* filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1)); */
  background: rgba(255, 255, 255, 0.8);
  width: 31px;
  height: 3px;
  border-radius: 0% !important;
  /* display: none; */
}

.swiper-pagination .swiper-pagination-bullet-active {
  /* background: #ff8c00; */
  @apply bg-primary
  /* display: none; */;
}

@media (max-width: 1280px) {
  .slidee {
    position: relative;
  }

  .slidee::before {
    position: absolute;
    content: "";
    left: -1em;
    right: -0.07em;
    top: 2em;
    bottom: -1em;
    background: rgb(107, 70, 17);
    background: linear-gradient(
      90deg,
      rgba(107, 70, 17, 1) 67%,
      rgba(148, 76, 4, 1) 100%
    );
    filter: blur(2spx);
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}
