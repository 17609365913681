html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}
/* styles.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "MontBlancRegular";
    src: local("MontBlancRegular"),
        url("/public/fonts/WEB/MontBlanc-Regular.otf") format("otf");
}

@font-face {
    font-family: "MontBlancThin";
    src: url("/public/fonts/WEB/MontBlanc-Thin.otf");
}

@font-face {
    font-family: "MontBlancRegular";
    src: url("/public/fonts/WEB/MontBlanc-Regular.otf");
}

@font-face {
    font-family: "MontBlancBold";
    src: url("/public/fonts/WEB/MontBlanc-Bold.otf");
}
@font-face {
    font-family: "MontBlancSemiBold";
    src: url("/public/fonts/WEB/MontBlanc-SemiBold.otf");
}
@font-face {
    font-family: "MontBlancBlack";
    src: url("/public/fonts/WEB/MontBlanc-Black.otf");
}

@font-face {
    font-family: "Sequel100Wide";
    src: local("Sequel100Wide"),
        url("/public/fonts/WEB/Sequel100Wide-96.ttf") format("truetype");
}

@font-face {
    font-family: "Sequel100Wide95";
    src: local("Sequel100Wide"),
        url("/public/fonts/WEB/Sequel100Wide-95.ttf") format("truetype");
}
@font-face {
    font-family: "Sequel100WideVF45";
    src: local("Sequel100WideVF45"),
        url("/public/fonts/WEB/Sequel100Wide-45.ttf") format("truetype");
}
@font-face {
    font-family: "Sequel100WideVF55";
    src: local("Sequel100WideVF55"),
        url("/public/fonts/WEB/Sequel100Wide-55.ttf") format("truetype");
}
@font-face {
    font-family: "Sequel100Wide-65";
    src: local("Sequel100Wide-65"),
        url("/public/fonts/WEB/Sequel100Wide-65.ttf") format("truetype");
}
@font-face {
    font-family: "Sequel100WideVF";
    src: local("Sequel100WideVF"),
        url("/public/fonts/WEB/Sequel100WideVF.ttf") format("truetype");
}

.fade_overflow:before {
    content:'';
    width:100%;
    height:100%;    
    position:absolute;
    left:0;
    top:0;
    background:linear-gradient(transparent 150px, white);
  }

.quill_renderer_dark * {
    color: white !important;
}

.quill_renderer {
    color: white !important;
}

.quill_renderer h1 {
    font-size: 2em;
    /* font-weight: bold; */
    margin: 0.67em 0;
  }
  
 .quill_renderer h2 {
    font-size: 1.5em;
    /* font-weight: bold; */
    margin: 0.83em 0;
  }
  
 .quill_renderer h3 {
    font-size: 1.17em;
    /* font-weight: bold; */
    margin: 1em 0;
  }
  
 .quill_renderer h4 {
    font-size: 1em;
    /* font-weight: bold; */
    margin: 1.33em 0;
  }
  
 .quill_renderer h5 {
    font-size: 0.83em;
    /* font-weight: bold; */
    margin: 1.67em 0;
  }
  
 .quill_renderer h6 {
    font-size: 0.67em;
    /* font-weight: bold; */
    margin: 2.33em 0;
  }

.quill_renderer p {
    margin: 1em 0;
    
  }


  


/* Apply padding to create space on the left and right */
.custom-scrollbar {
    overflow-y: auto; /* Ensure scroll is enabled */
    /* height: 200px; Example height */
    margin-left: 0px; /* Left margin equivalent */
    margin-right: 20px; /* Right margin equivalent */
}

/* Custom Scrollbar Styling */
.custom-scrollbar::-webkit-scrollbar {
    width: 4px; /* Width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
    margin-top: 25px;
    margin-bottom: 25px;
    background: #3b3b3b; /* Dark background color for the track */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #999999; /* Gray color for the thumb */
    border-radius: 10px; /* Rounded corners */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #999999; /* Darker gray on hover */
}

@font-face {
  font-family: "Sequel100Wide85";
  src: local("Sequel100Wide85"),
    url("/public/fonts/WEB/Sequel100Wide-85.ttf") format("truetype");
}

@font-face {
  font-family: "Sequel100Wide46";
  src: local("Sequel100Wide46"),
    url("/public/fonts/WEB/Sequel100Wide-46.ttf") format("truetype");
}

html {
    font-size: 16px;
    box-sizing: border-box;
    overflow-x: hidden;
}

body {
    margin: 0;
    font-family: MontBlancRegular;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    overflow-x: hidden;
    /* font-family: MontBlanc; */

    @apply bg-general_bg;
}

.truncate-8-chars {
    display: inline-block;
    max-width: 8ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.truncate-almost-screen-width {
    display: inline-block;
    max-width: 80vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.faqContainer {
    background: linear-gradient(
        45deg,
        rgba(115, 27, 80, 1) 0%,
        rgba(43, 43, 43, 1) 20%
    );
}

.winnerContainer {
    background: linear-gradient(
        45deg,
        rgba(115, 27, 80, 1) 0%,
        rgba(43, 43, 43, 1) 20%
    );
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
